<template>
  <div :class="{ disabled }" class="tagsList">
    <transition-group
      tag="div"
      class="tagsListWrapper is-marginless field is-grouped is-grouped-multiline"
      name="list"
    >
      <a
        v-for="tag in value"
        :key="keyFormatter(tag)"
        class="tag is-primary m-r-s"
      >
        <slot :tag="tag">
          {{ formatter(tag) }}
        </slot>
        <button type="button" class="delete is-small" @click="removeTag(tag)" />
      </a>
      <div key="input" class="control is-expanded">
        <input
          v-if="!removeOnly"
          v-model.trim="newTag"
          :name="name"
          :placeholder="placeholder"
          :disabled="disabled"
          type="text"
          class="tagsListInput"
          @input="handleInput"
          @keyup.delete="tryToRemoveTag"
          @keyup.188="addNewTag"
          @keyup.59="addNewTag"
          @keydown.tab="addNewTag"
          @blur="addNewTag"
          @paste="handlePaste"
          @keydown.enter.stop.prevent="addNewTag"
        >
      </div>
    </transition-group>
    <form-field key="errors" :validator="$v.newTag" attribute="general.info" />
  </div>
</template>
<script>
export default {
  name: 'TagsList',
  props: {
    value: {
      type: Array,
      required: true
    },
    removeOnly: {
      type: Boolean,
      default: false
    },
    formatter: {
      type: Function,
      default: (tag) => tag
    },
    keyFormatter: {
      type: Function,
      default: (tag) => tag.id || tag
    },
    validator: {
      type: Object,
      default: () => ({})
    },
    placeholder: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'tags'
    },
    separator: {
      type: [String, RegExp],
      default: ','
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  validations () {
    return {
      newTag: this.validator
    }
  },
  data () {
    return {
      newTag: '',
      // shows if we tried to delete on an empty field
      triedToDelete: false
    }
  },
  computed: {
    tags: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    addNewTag (e) {
      let separator = new RegExp((this.separator.source || this.separator) + '$')
      // remove comma at the end
      let tag = this.newTag.replace(separator, '')
      if (!tag) {
        this.newTag = ''
        return
      }
      // check if it has already been added
      this.newTag = tag
      if (this.isAlreadyAdded(tag)) {
        this.newTag = ''
        return
      }
      // if we have errors, dont pass
      if (this.$v.newTag.$error) return
      this.tags = [...new Set([...this.value, ...tag.split(this.separator)])]
      this.newTag = ''
    },

    // Removes the tag on delete or backspace
    tryToRemoveTag () {
      // if there is a tag value it does not remove it
      if (this.newTag) return
      // if we have not tried deleting before (input still had value) we bail out
      // but set it to true, so next time we delete, we remove the tag
      if (!this.triedToDelete) {
        this.triedToDelete = true
        return
      }
      const tagsClone = this.tags.slice(0)
      tagsClone.pop()
      this.tags = tagsClone
    },

    removeTag (tag) {
      this.$emit('remove', tag)
      const index = this.tags.findIndex(t => t === tag)
      const tagsClone = this.tags.slice()
      tagsClone.splice(index, 1)
      this.tags = tagsClone
    },

    isAlreadyAdded (tag) {
      return this.value.includes(tag)
    },

    async handlePaste (e) {
      await this.$nextTick()
      this.newTag = e.target.value
      this.addNewTag()
    },

    handleInput () {
      this.$v.newTag.$touch()
      this.triedToDelete = false
    }
  }
}
</script>
<style lang='scss'>
@import "~utils";

$marginBottom: 10px;
.tagsList {
  border: 1px solid $hf__input-border-color;
  background: $white;
  padding: 4px 5px;

  &.disabled {
    border-color: $grey-light;
    background: $grey-lighter;
  }

  .tagsListWrapper {
    margin-bottom: -$marginBottom !important;
  }

  .field {
    align-items: center;
  }

  min-height: 38.5px; // I know, I know...

  .control {
    margin-bottom: 0 !important;
  }

  input.tagsListInput {
    width: 100%;
    background: none;
    border: none;
    min-height: 0 !important;
    height: 24px;
    padding: 0;
    margin-bottom: $marginBottom;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .tag {
    margin-bottom: $marginBottom;
    font-size: 12px;

    .delete {
      transform: scale(0.85);
      background: $white;

      &:before {
        background: $primary;
        height: 1px;
      }

      &:after {
        background: $primary;
        width: 1px;
      }
    }
  }
}
</style>
